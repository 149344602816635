
.products {

  padding: 2*$unitMult+$unit;
  flex: 1;

  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;





    .intro {
      .btn {
        margin-left: 1*$unitMult+$unit;
      }
    }
    .product-status {
      flex: 1;
      font-size: 3*$unitMult+$unit;
      align-items: center;
      justify-content: center;
      display: flex;
      text-align: center;
    }





  .products-list {
    margin: (2*$unitMult+$unit) (-2*$unitMult+$unit);
    margin-top:1*$unitMult+$unit;
    background-color: #ffffff;
    border: solid 1px #ccc;


    .swiper-container {
      padding: 2*$unitMult+$unit;
      // overflow: visible;
    }

    .product {

      .image {
        // padding: 1*$unitMult+$unit;
        height: 15*$unitMult+$unit;
        img {
          height: 15*$unitMult+$unit;
        }
        svg {
          height: 15*$unitMult+$unit;
          path {
            fill: #999;
          }
        }
      }

      border-radius: 1*$unitMult+$unit;
      border: solid 2px #ccc;
      // padding: 1*$unitMult+$unit;
      background: #ffffff;

      display: flex;
      flex-direction: column;
      align-items: center;
      &.active {
        border-color: $selection;
        box-shadow: 0 0 1*$unitMult+$unit $selection;
      }

      .title {


        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        margin-top: 1*$unitMult+$unit;
        padding: 0 1*$unitMult+$unit;

      }

    }


  }









  .product-details {

    flex: 1;

    overflow: scroll;

    .info {
      flex: 1;
      padding-right: 2*$unitMult+$unit;
      position: relative;
    }

    .video-button {
      position: absolute;
      top: 0;
      right: 2*$unitMult+$unit;

      button {
          display: flex;
          align-items: center;
        img {
          margin-right: 1*$unitMult+$unit;
          width: 3*$unitMult+$unit
        }
      }
    }

    .important-vehicle-info {
      background-color: #cae7ff;
      img {
        width: 3*$unitMult+$unit;
      }
      padding: 1*$unitMult+$unit;
      border: solid 1px #ccc;
      margin-bottom: 1*$unitMult+$unit;

    }
    .image {
      margin-bottom: 1*$unitMult+$unit;
      background: #ffffff;
      border: solid 1px #ccc;
      padding: 2*$unitMult+$unit;
      border-radius: 2*$unitMult+$unit;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 40*$unitMult+$unit;
      margin-right: 5*$unitMult+$unit;
      overflow: hidden;

      img, svg {
        width: 100%;
        height: auto;
      }
    }
  }






  .product-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;

    .close {
      font-size: 5*$unitMult+$unit;
      position: absolute;
      right: 3*$unitMult+$unit;
      top: 2*$unitMult+$unit;
    }

    .backdrop {

      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.5);
    }

    .content {
      position: relative;
      background-color: #ffffff;
      border-radius: 1*$unitMult+$unit;
      z-index: 1;
      padding: 5*$unitMult+$unit;
      width: 80*$unitMult+$unit;


      text-align: center;
      canvas {
        margin: 4*$unitMult+$unit 0;
      }

    }
  }
}
