

.product-filters{
  flex: 1;
  overflow: scroll;
  position: relative;
  padding: 2*$unitMult+$unit;
  display: flex;
  flex-direction: column;

  .filter-buttons {

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .btn {
      display: block;
      margin-top: 1*$unitMult+$unit;
      font-size: 3*$unitMult+$unit;

    }

  }
}
