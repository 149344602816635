
$unit:rem;
$unitMult:1;

@import "../index";


#root {
  display: flex;
}

.splash {
  display: flex;
  flex:1;
  align-items: center;
  justify-content: center;

  font-size: 1.5rem;
  padding: 2rem;
  text-align: center;

}

.start {
  .banner {
    // display: none;
  }
}


.app {

  padding-bottom: 4rem;

  .current-car {
    .vehicle {
      flex-wrap: wrap;
      font-size: 1rem;
      > div {
        margin-left: .5rem;
      }

      .regno .country .inner {
        width: .75rem;
      }
    }


    padding: .25rem;

    h3 {
      // display: none;
      font-size: 1rem !important;
    }

    .reset-vehicle {
      display: none;
    }
  }


  .subcategory {
    padding: 1rem;
    .buttons {
      padding: 0;
      .btn {
        font-size: 1.5rem;
      }
    }
  }

  .products {
    padding: 1rem;
    overflow: visible;

    .intro {
      .btn {
        padding: 0.5rem 0.5rem;
        margin-left: auto;
      }
    }


    .products-list {
      .swiper-container {
        // padding: 1rem;

        .swiper-button-next:after, .swiper-button-prev:after {
          font-size: 1rem !important;
        }

        .swiper-pagination {
            bottom: 0rem !important;
        }
      }

      .product {
        .image, img {
          height: 5rem;

        }
      }
    }

    .product-details {
      flex-direction: column;
      .image {
        width: 100%;
        margin-right: 0;

        img {
          // height: 10rem;
          height: auto;
          width: 8rem;
        }

      }

      .video-button {
        img {
          width: 2rem;
        }
        margin-bottom: .5rem;
        right: 0rem;
        // top: -3rem;
        position: relative;
        font-size: 1rem;
      }
      .info {
        padding-right: 0;
        .important-vehicle-info img {
          width: 1rem;
        }
        padding-bottom: 2rem;
      }

    }

    .product-status {
      font-size: 1.5rem;
    }

    .product-filters {
      padding: 0px;
      .filter-buttons {
        justify-content: flex-start;
        margin-top: 2rem;

        .btn {
          display: block;
          margin-top: .5rem;
          font-size: 1rem;
        }
    }

    }
  }


  .bottom-bar {

    position: fixed;
    bottom: 0;
    left: 0;
    right:0;
    padding: 1rem;
    height: 4rem;

    min-height: initial;
    font-weight: bold;
    font-size: .5rem;
    .category {
      margin: 0 1rem;
    }
    >div {
      width: initial;

    }

    svg {
      max-height: 2rem;
      max-width: 3rem;
    }

  }

  .kiosk-bar {
    display: none;
  }


  .start {
    h2 {
      font-size: 1.5rem;
      font-weight: bold;
    }
    .copy {
      // margin-top: 2rem;
      font-size: 1rem;
      position: relative;
      top:0;

    }
    .buttons {
      padding: 0;
      .category-item {
        width: calc(50% - 1rem);
        margin: 0.5rem;
        height: 150px;
        padding: .4rem;
        svg {
          width: 50%;
          margin-top: -1rem;
        }
        h3 {
          font-size: 1rem;
        }
      }
    }
  }

  .find-vehicle {
    h1 {
      font-weight: bold;
    }
    .input-row {
      flex-direction: column;

      .input {
        .country {
          padding: .5rem;
        }
        .inner {
          width: 2rem;
          // height: 1rem;
          background-size: contain;
          background-position: center;

        }



        input {
          width: 100%;

        }
      }
      .btn {
        font-size: 2rem;
        margin-left: 0;
        margin-top: 2rem;
      }
    }
  }


}






body {
  position: relative;
}


body, .btn {
  font-size: 1rem;
}

h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.3rem;
}
h3 {
  font-size: 1.2rem;
}
h4 {
  font-size: 1.1rem;
}
h5 {
  font-size: 1rem;
}
