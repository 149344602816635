

.find-vehicle {
  flex:1;
  position: relative;

  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  padding: 2*$unitMult+$unit;

  overflow: scroll;

  align-items: center;

  .input-row {
    display: flex;
    align-items: center;
    margin-bottom: 2*$unitMult+$unit;

    .btn {
      margin-left: 3*$unitMult+$unit;
      // height: 100%;
      font-weight: bold;
      font-size: 3*$unitMult+$unit;
    }


    .input {

      display: flex;
      flex-direction: row;

      border: solid 2px #ccc;
      border-radius: 1*$unitMult+$unit;
      overflow: hidden;
      background: #fff;

      .country {
        background-color: #0b65c1;
        padding: 2*$unitMult+$unit;

        .inner {
          width: 7*$unitMult+$unit;
          height: 100%;

          background-image: url('../images/vehicle/license_plate_0003_se.png');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      &.lang-no .inner{
        background-image: url('../images/vehicle/license_plate_0004_no.png');
      }



      input {
        // --webkit-user-select: auto;
        padding: .5rem;
        border: none;
        text-transform: uppercase;
        font-size: 2rem;
      }
      .items {
        padding: 2*$unitMult+$unit;
        display: flex;
        flex-direction: row;
        overflow: auto;

      }
      .item {



        margin: 1*$unitMult+$unit;
        font-weight: bold;
        font-size: 4*$unitMult+$unit;
        width: 10*$unitMult+$unit;
        height: 10*$unitMult+$unit;
        padding: 1*$unitMult+$unit;
        border-radius: 2*$unitMult+$unit;
        border: solid 1px #ccc;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
          // background-color: #eee;
          background-color: #cee9ff;
          border: solid 1px #2369a5;
        }
      }
    }

  }



  .virtual-keyboard ul {


    list-style: none;

    // flex-direction: column;
    width:100%;
    margin:0;
    padding:0;

    div {
      display: flex;
      flex-wrap: wrap;
      width:100%;
      justify-content: center;
    }

    li {
      background-color: #ffffff;
      margin: .75*$unitMult+$unit;
      font-weight: bold;
      font-size: 4*$unitMult+$unit;
      width: 10*$unitMult+$unit;
      height: 10*$unitMult+$unit;
      padding: 1*$unitMult+$unit;
      border-radius: 2*$unitMult+$unit;
      border: solid 1px #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      &:active {
        background-color: #ddd;
      }

    }
  }
}
