
@import "./site/variables";
@import "./site/fonts";

@import "~bootstrap/scss/bootstrap";


html, body, #root, .app {
  height: 100%;
  width: 100%;
}



body, .btn {
  font-size: 2.5*$unitMult+$unit;
}

h1 {
  font-size: 5*$unitMult+$unit;
}
h2 {
  font-size: 4*$unitMult+$unit;
}
h3 {
  font-size: 3*$unitMult+$unit;
}
h4 {
  font-size: 2*$unitMult+$unit;
}
h5 {
  font-size: 1*$unitMult+$unit;
}




body, h1, h2, h3, h4, h5, h6 {
  font-family: 'Source Sans Pro', sans-serif;
}



.swiper-button-next, .swiper-button-prev {
  &:after{
    color: #000;
    font-size: 3*$unitMult+$unit !important;

  }
}
.swiper-pagination {
  bottom: -.5*$unitMult+$unit !important;
  .swiper-pagination-bullet-active{
    background-color: #000;

  }
}




.app {
  display: flex;
  flex-direction: column;
  background-color: $dimmedBackground;


}



.spinner-container {
  position: absolute;
  z-index: 99;
  bottom: 0px;
  top:0px;
  right:0px;
  left:0px;
  background-color: rgba(255,255,255,.5);
  display: flex;
  align-items: center;
  justify-content: center;
}




@import "./bottom-bar";
@import "./filters";
@import "./find-vehicle";
@import "./products";
@import "./start";
@import "./subcategory";
@import "./vehicle-bar";

@import "./site/overrides";
