
.bottom-bar {
  flex:0;
  display: flex;

  align-items: center;
  justify-content: center;
  padding: 2*$unitMult+$unit;

  background-color: $primary;
  color: #ffffff;
  min-height: 10*$unitMult+$unit;

  font-weight: bold;
  font-size: 2*$unitMult+$unit;

  >div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20*$unitMult+$unit;
    opacity: .7;
    &.active {
      opacity: 1;
    }
  }


  .home {
    margin-right: auto;

  }
  .vehicle {
    margin-left: auto;
    &.hide {
      visibility: hidden;
    }
  }

  svg {
    max-height: 5*$unitMult+$unit;
    max-width: 8*$unitMult+$unit;
    path, rect, polygon {
      fill: #ffffff;
    }
  }
}
